<template>
  <div class="adset">
    <div class="adset-list">
        <list-bar :params="params"  @setParams="setParams" :dateRange="dateRange"></list-bar>
        <p class="campaign-name">{{adsetMsg.ad.adGroupName}}</p>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="list.length">
          <ads-card v-for="(item,index) in sortList" :key="item.adId" :data="{ad:item.ad,statistics:{...item.statistics,productReport:adsetMsg.statistics.productReport},campaignCost:adsetMsg.statistics.spend}" :index="index" @changeStatus="changeStatus" @setData="setData"></ads-card>
        </van-pull-refresh>
        <van-empty description="暂无数据" v-else />
    </div>
  </div>
</template>
<script>
import listBar from "@/views/components/listBar.vue";
import adsCard from "@/views/ads/adsCard.vue"
import globalData from '@/minixs/data.js'
import editAdMix from "@/views/components/editAdMix.js";
// import { Empty } from 'vant';
// const statusList = [
//   {key:'ACTIVE',val:1},
//   {key:'PAUSED',val:2},
//   {key:'DELETED',val:3},
// ]
// function getIndex(key){
//   return statusList.filter(v=>v.key == key).length?statusList.filter(v=>v.key == key)[0][`val`]:100;                                  
// }
export default {
  components: {
    listBar,
    adsCard
  },
  mixins:[globalData,editAdMix],
  data() {
    return {
      params:{
          currency:null,
          time:1,
          syncData:false,
          sort:'origin_spend#DESC'
      },
      adsetMsg:{}
    };
  },
  computed:{
    sortList:{
      get(){
        return this.list;
        // this.list.map(v=>{
        //   v.statusIndex = getIndex(v.ad.adGroupStatus)
        // })
        // let list1 = this.list.filter(v=>v.ad.adGroupStatus == 'ACTIVE').sort((a,b)=>b.statistics.spend - a.statistics.spend)
        // let list2 = this.list.filter(v=>v.ad.adGroupStatus == 'PAUSED').sort((a,b)=>b.statistics.spend - a.statistics.spend)
        // let list3 = this.list.filter(v=>v.ad.adGroupStatus == 'DELETED').sort((a,b)=>b.statistics.spend - a.statistics.spend)
        // let list4 = this.list.filter(v=>v.ad.adGroupStatus != 'ACTIVE' && v.ad.adGroupStatus != 'PAUSED' && v.ad.adGroupStatus != 'DELETED').sort((a,b)=>b.statistics.spend - a.statistics.spend)
        // return list1.concat(list2).concat(list3).concat(list4)
        // return this.list
      },
      set(){

      }
    }
  },
  methods:{
    getList(){
      if(!this.params.time && !this.dateRange){
        return
      }
      let {platform,credentialId,adAccountId,adAccountName,adAccountStatus,adGroupId,adGroupName} = this.adsetMsg.ad
      let params = {
        platform,
        startDate: this.params.time?this.formatDate(
          this.dateList.filter((v) => v.value == this.params.time)[0].start(),
          { tag: 1 }
        ):this.formatDate(this.dateRange[0],{ tag: 1 }),
        endDate: this.params.time?this.formatDate(
          this.dateList.filter((v) => v.value == this.params.time)[0].end(),
          { tag: 1 }
        ):this.formatDate(this.dateRange[1],{ tag: 1 }),
        currency:this.params.currency,
        level: "ad",
        orderBy:this.params.sort.split('#')[0],
        ascOrDesc:this.params.sort.split('#')[1],
        ad: {platform,credentialId,adAccountId,adAccountName,adAccountStatus,adGroupId,adGroupName},
      };
      this.getDefaultList(params);
    },
    changeStatus(params,idKey,id,key,val){
      this.editStatus(params,idKey,id,key,val)
    },
  },
  mounted(){
    // this.getList();
  },
  created(){
      let {adsetMsg,currency,time} = this.$route.query;
      // dateRange = JSON.parse(dateRange)
      let {dateRange} = this.$route.query;
      dateRange = JSON.parse(dateRange);
      this.adsetMsg = JSON.parse(adsetMsg)
      this.params.currency = currency;
      this.params.time = time?time/1:null;
      this.dateRange = dateRange && dateRange.length?dateRange:null;
      if(this.dateRange){
        this.dateRange = [new Date(this.dateRange[0]),new Date(this.dateRange[1])]
      }
      if(!this.params.time && this.dateRange){
        this.getList()
      }
      // this.getList()
  }
};
</script>
<style lang="less">
.adset {
.van-dropdown-menu__title--active,.van-dropdown-item__option--active .van-dropdown-item__icon,.van-dropdown-item__option--active{
    color: #1989fa;
}
  .top-bar {
    display: flex;
    width: 100%;
    .platform {
      flex: 240px 0 0;
      .van-dropdown-menu__bar {
        box-shadow: none;
        
      }
     
    }
    .search {
      flex: 1;
    }
  }
  .adset-list{
      padding: 0 40px;
      border-top: 1px solid #ebedf0;
      .campaign-name{
          font-size: 32px;
          padding-bottom: 20px;
          border-bottom: 1px solid #ebedf0;
      }
  }
}
</style>