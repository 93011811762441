<template>
  <div class="adset-card">
    <div class="card-title">
      <p>
        <span v-show="!showInput">{{ data.ad.adName }}</span>
        <van-field
          class="editNameInput"
          v-show="showInput"
          v-model="newName"
          placeholder="请输入用户名"
        >
          <template #button>
            <van-button
              type="primary"
              class="mr5"
              size="mini"
              @click.stop="changeName"
              :loading="loading"
              >确认</van-button
            >
            <van-button
              type="default"
              size="mini"
              @click.stop="setShowInput(false)"
              >取消</van-button
            >
          </template>
        </van-field>
      </p>
      <!-- <p class="account">{{data.accountName}}</p> -->
    </div>
    <div class="adsetData" v-if="data.statistics">
      <div class="data-item">
        <p class="desc">花费</p>
        <span
          >{{ data.statistics.currency
          }}{{ data.statistics.spend.toFixed(2) }}</span
        >
      </div>
      <div class="data-item">
        <p class="desc">订单/成本</p>
        <span
          >{{ data.statistics.orderCount }}/{{
            data.statistics.perOrderCost.toFixed(2)
          }}</span
        >
      </div>
      <div class="data-item">
        <p class="desc">添加支付/成本</p>
        <span
          >{{ data.statistics.addPayCount }}/{{
            data.statistics.perAdPayCost.toFixed(2)
          }}</span
        >
      </div>
      <div class="data-item" v-if="data.ad.platform == 'line'">
        <p class="desc">dopay/成本</p>
        <span
          >{{ data.statistics.dopay }}/{{
            data.statistics.finalCost
              ? data.statistics.finalCost.toFixed(2)
              : "--"
          }}</span
        >
      </div>
      <div class="data-item">
        <p class="desc">毛利率</p>
        <span>{{
          productReport() && productReport().profitRatePre
            ? productReport().profitRatePre.toFixed(2) + "%"
            : "--"
        }}</span>
      </div>
      <div class="data-item">
        <p class="desc">
          {{ data.ad.platform == "twitter" ? "链接" : "" }}点击数
        </p>
        <span>{{ data.statistics.click || 0 }}</span>
      </div>
      <div class="data-item">
        <p class="desc">
          {{ data.ad.platform == "twitter" ? "链接" : "" }}点击成本
        </p>
        <span>{{ data.statistics.perClickCost || "--" }}</span>
      </div>
      <div class="data-item" v-if="data.ad.platform == 'twitter'">
        <p class="desc">点赞数</p>
        <span>{{ data.statistics.like || 0 }}</span>
      </div>
    </div>
    <div class="tools">
      <div class="tool-item status">
        <span>状态：</span>
        <van-switch
          :value="data.ad.adStatus"
          @input="onInput"
          active-value="ACTIVE"
          inactive-value="PAUSED"
          size="16px"
          :disabled="
            data.ad.adStatus == 'DELETED' || data.ad.adStatus == 'DELETED'
          "
          id="changeStatus"
        />
      </div>
    </div>
    <!-- 改预算 -->
    <edit-budget
      :editBudgetShow.sync="editBudgetShow"
      :data="data"
      level="adset"
      :type="type"
      @setListItem="setListItem"
    ></edit-budget>
    <!-- 复制 -->
    <van-popup v-model="showCopy" position="bottom" :closeable="true">
      <div class="copy-wrap">
        <van-field
          v-model="copyCount"
          label="复制份数："
          placeholder="请输入复制份数"
          type="digit"
        />
        <div class="btn-wrap">
          <van-button
            type="default"
            size="small"
            round
            @click="showCopy = false"
            >取消</van-button
          >
          <van-button type="info" size="small" round @click="copy"
            >确定</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import editBudget from "@/views/components/editBudget.vue";
import globalData from "@/minixs/data";
import { Dialog, Notify } from "vant";
import { copyAdset, mutateName } from "@/api/campaign";
import { ProductReport } from "@/views/adset/ProductReport";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  mixins: [globalData],
  components: {
    editBudget,
  },
  data() {
    return {
      editBudgetShow: false,
      type: "budget",
      showCopy: false,
      copyCount: 1,
      showInput: false,
      newName: "",
      loading: false,
    };
  },
  methods: {
    onInput(v) {
      console.log(this.data);
      if (this.data.ad.adStatus == "CLOSED") {
        Notify({ type: "danger", message: "关闭状态不支持修改哦~~" });
        return false;
      }
      let params = {
        platform: this.data.ad.platform,
        level: "ad",
        accountId: this.data.ad.adAccountId,
        adsetId: this.data.ad.adGroupId,
        credentialId: this.data.ad.credentialId,
        campaignId: this.data.ad.campaignId,
        adId: this.data.ad.adId,
        status: v,
      };
      this.$emit(
        "changeStatus",
        params,
        this.data.ad.adId,
        "adId",
        "adStatus",
        v
      );
    },
    editBudget() {
      if (
        this.data.ad.adGroupStatus == "DELETED" ||
        this.data.ad.adGroupStatus == "DELETED"
      ) {
        Notify({ type: "danger", message: "关闭状态不支持修改哦~~" });
        return false;
      }
      this.type = "budget";
      this.editBudgetShow = true;
    },
    editBidAmount() {
      if (
        this.data.ad.adGroupStatus == "DELETED" ||
        this.data.ad.adGroupStatus == "DELETED"
      ) {
        Notify({ type: "danger", message: "关闭状态不支持修改哦~~" });
        return false;
      }
      this.type = "bidAmount";
      this.editBudgetShow = true;
    },
    productReport() {
      if (
        this.data.statistics.productReport &&
        Object.keys(this.data.statistics.productReport).length
      ) {
        let purchase = this.data.statistics.orderCount;
        let moneyRate =
          this.data.statistics.productReport.adCost / this.data.campaignCost;
        let spend = this.data.statistics.spend * moneyRate;
        // let purchase = this.value.row.local_order?`${this.value.row.local_order.validOrderCount}`:0
        return new ProductReport(
          this.data.statistics.productReport,
          // this.value.row.campaignPurchase,
          purchase,
          // this.value.row.campaignCost,
          spend
        );
      } else {
        return {};
      }
    },
    // 跳转经营分析
    goReport(val) {
      if (val) {
        this.$router.push({
          name: "productReport",
          query: { val: JSON.stringify(val) },
        });
      }
    },
    copySure() {
      let params = {
        adsetIds: [this.data.ad.adGroupId],
        thirdUserId: "",
        uId: JSON.parse(localStorage.getItem("userInfo")).id,
        copyCount: this.copyCount,
        accountId: this.data.ad.adAccountId,
        credentialId: this.data.ad.credentialId,
      };
      this.$showLoading();
      copyAdset(params, this.data.ad.platform)
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            Notify({ type: "success", message: "复制成功，请稍后查看结果" });
            this.showCopy = false;
            this.copyCount = 1;
          }
        })
        .catch(() => {
          this.$hideLoading();
        });
    },
    // 复制
    copy() {
      Dialog.confirm({
        title: "确认",
        message: `确定要复制${this.copyCount}份该广告组到当前广告系列吗？`,
      })
        .then(() => {
          this.copySure();
        })
        .catch(() => {
          // on cancel
        });
    },
    copyAdset() {
      this.showCopy = true;
    },
    deleteAdset(v) {
      Dialog.confirm({
        title: "确认",
        message: "确定要删除该广告组",
      })
        .then(() => {
          this.onInput(v);
        })
        .catch(() => {
          // on cancel
        });
      // this.onInput(v)
    },
    // 修改预算
    setListItem(key, val) {
      this.$emit("setData", this.data.ad.adGroupId, "adGroupId", key, val);
    },
    // 编辑名字
    editName() {
      this.newName = this.data.ad.adName;
      this.showInput = true;
    },
    changeName() {
      this.loading = true;
      const {
        ad: { platform, campaignId, adAccountId, adGroupId, adId },
      } = this.data;
      const newName = this.newName;
      let params = {
        platform: platform,
        campaignId: campaignId,
        accountId: adAccountId,
        name: newName,
        level: "ad",
        adsetId: adGroupId,
        adId,
      };
      mutateName(params)
        .then(() => {
          this.showInput = false;
          // this.$emit("getList");
          this.$set(this.data.ad, "adName", newName);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setShowInput(bool) {
      console.log(bool);
      this.showInput = bool;
      console.log(this.showInput);
    },
  },
};
</script>
<style lang="scss" scoped>
.copy-wrap {
  margin-top: 100px;
  padding: 20px;
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-top: 50px;
    padding-bottom: 0;
    .van-button {
      width: 150px;
    }
  }
}
.adset-card {
  border-bottom: 1px solid #ebedf0;
  padding: 10px 0;
  .card-title {
    // display: flex;
    width: 100%;
    font-size: 30px;
    line-height: 1.5;
    .account {
      font-size: 24px;
      color: #999;
    }
  }
  .adsetData {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    font-size: 26px;
    flex-wrap: wrap;
    .data-item {
      width: 25%;
    }
    .desc {
      margin-bottom: 20px;
    }
    span {
      color: #ff0000;
      line-height: 52px;
    }
  }
  .tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    .status {
      width: 200px;
      height: 42px;
      span {
        vertical-align: super;
      }
    }
  }
}
.editNameInput {
  background: #efefef;
  border-radius: 5px;
}
.mr5 {
  margin-right: 5px;
}
</style>
